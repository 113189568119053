<template>
  <div class="grid-x">
    <div class="cell small-12 medium-8 bg-grey pb-5">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 mb pt-5">
          <h1 class="text-white">
            Budget Impact Model
          </h1>
          <h2 class="text-white">
            Link Generator
          </h2>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 mb pt-5">
          <h2 class="text-white">CREATE A LIVE LINK</h2>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-3 medium-offset-1 mb pt-5">
          <label>Customer name</label>
        </div>
        <div class="cell small-12 medium-7 mb pt-5">
          <input v-model="customerName" type="text">
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 mb pt-5">
          <button @click="generateLink" class="button expanded">GENERATE</button>
        </div>
      </div>
      <div
        v-if="link.length > 0"
        class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 mb pt-5">
          <h4 class="text-white">Link</h4>
        </div>
      </div>
      <div
        v-if="link.length > 0"
        class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 mb pt-5">
          <div class="link-wrap">
            <input
              class="link-holder"
              v-on:focus="$event.target.select()"
              ref="clone"
              readonly
              :value="link"/>
            <button @click="copy" class="link-copy">COPY LINK</button>
          </div>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-7 medium-offset-1 mb pt-40">
          <p class="text-small text-white">
            Biogen-192046 Date of preparation: January 2023
          </p>
        </div>
        <div class="cell small-12 medium-3 mb pt-40">
          <p class="text-right">
            <router-link class="image-circle" to="/link-list">
              <img width="40px" src="../../assets/logos/contacts.svg" alt="" />
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="cell small-12 medium-4">
      <div :style="{ height: viewportHeight + 'px' }" class="relative">
        <img class="top-logo" src="../../assets/logos/biogen_logo.svg" alt="" />
        <img class="bottom-logo" src="../../assets/logos/Byooviz_logo.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'Links',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      link: '',
      viewportHeight: 0,
      customerName: '',
    };
  },
  methods: {
    copy() {
      this.$refs.clone.focus();
      document.execCommand('copy');
    },
    generateLink() {
      const postData = {};
      postData.userid = this.userid;
      postData.customerName = this.customerName;
      axios.post(`/links/generateLiveLink.json?token=${this.token}&user=${this.userid}`, postData)
        .then((response) => {
          this.link = response.data.link;
        });
    },
  },
  mounted() {
    this.viewportHeight = window.innerHeight;
  },
};
</script>
